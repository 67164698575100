<template>
  <!-- 保险注册页面 -->
  <div class="index">
    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />

    <div class="build"></div>
    <ServerTop
      title="商标驳回复审"
      wera="资深律师全程代理，不成功免费重新申请"
      jiage="2850"
    />

    <div class="importance">
      <div class="header">
        <div class="lan"></div>
        <p>服务详情</p>
      </div>
      <div class="title">
        <img src="../imger/AR/title.png" alt="" class="titletubiaoleft" />
        <p>服务介绍</p>
        <img src="../imger/AR/title.png" alt="" class="titletubiaoright" />
      </div>
      <div class="word">
        <p>
            商标局认为所申请商标不符合规定或者在部分指定商品上不符合规定，予以驳回或者部分驳回，申请人可以在收到驳回通知书后有权向商评委提交商标注册复审申请。商标复审一般都要选择资深商标律师全程代理，最大程度提高商标复审成功率。
        </p>
      </div>
      <div class="bigbox">
        <div class="title">
          <img src="../imger/AR/title.png" alt="" class="titletubiaoleft" />
          <p>申请条件</p>
          <img src="../imger/AR/title.png" alt="" class="titletubiaoright" />
        </div>
        <div class="bigboxBox">
          <div class="tiao">
            <span class="tiaoWord01">申请人资格?</span>
            <p class="tiaoWord02">必须是被商标局驳回商标注册申请的原申请人。</p>
          </div>
          <div class="tiao">
            <span class="tiaoWord01">申请的时限?</span>
            <p class="tiaoWord02">商标注册申请人收到商标局对该商标注册申请的《商标核驳通知书》之日起，十五天内申请复审。因不可抗拒的事由或者其他正当理由迟延的，可以在期满前申请延期三十天，是否准许延期，由商标评审委员会决定。</p>
          </div>
        </div>
      </div>
      <div class="bigbox">
        <div class="title">
          <img src="../imger/AR/title.png" alt="" class="titletubiaoleft" />
          <p>服务优势</p>
          <img src="../imger/AR/title.png" alt="" class="titletubiaoright" />
        </div>
        <div class="advantage">
          <div class="advantageBox one">
            <p class="advantageBoxword">
              <span class="advantageBoxTitle">正规专业</span>
              <span class="advantageBoxWera"
                >国家商标局备案机构，全程人工1对1服务</span
              >
            </p>
          </div>
          <div class="advantageBox tow">
            <p class="advantageBoxword">
              <span class="advantageBoxTitle">快速安全</span>
              <span class="advantageBoxWera">专业团队，无障碍快速对接服务</span>
            </p>
          </div>
          <div class="advantageBox three">
            <p class="advantageBoxword">
              <span class="advantageBoxTitle">服务保障</span>
              <span class="advantageBoxWera"
                >签订合同，未成功免费重新再注册</span
              >
            </p>
          </div>
          <div class="advantageBox for">
            <p class="advantageBoxword">
              <span class="advantageBoxTitle">风险把控</span>
              <span class="advantageBoxWera"
                >大数据和人工双重检索，最大降低风险</span
              >
            </p>
          </div>
        </div>
      </div>
      
    </div>

    <div class="process">
      <ServerFoot :data="serverData" :show="true"/>
      <!-- 相关材料 -->
  <div class="AR01">
    <div class="header">
      <div class="lan"></div>
      <p>相关材料</p>
    </div>
    <div class="materials" >
      <div class="materialsBox">
        <p class="title">客户需准备的资料</p>
        <div class="field">
          <p class="num">1.</p>
          <p>驳回复审申请书（盖章/签字）</p>
          <img src="../imger/AR/bei.png" alt="" class="bei" />
        </div>
        <div class="field">
          <p class="num">2.</p>
          <p>评审代理委托书（盖章/签字）</p>
          <img src="../imger/AR/bei.png" alt="" class="bei" />
        </div>
        <div class="field">
          <p class="num">3.</p>
          <p>驳回复审正文</p>
          <p class="beizhu">（问标网准备）</p>
          <img src="../imger/AR/bei.png" alt="" class="bei" />
        </div>
        <div class="field">
          <p class="num">4.</p>
          <p>申请人主体资格证明文件（盖章/签字）</p>
          <img src="../imger/AR/bei.png" alt="" class="bei" />
        </div>
        <div class="field">
          <p class="num">5.</p>
          <p>证据目录和证据材料</p>
          <p class="beizhu">（问标网准备）</p>
          <img src="../imger/AR/bei.png" alt="" class="bei" />
        </div>
      </div>
    </div>
  </div>
    </div>

    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";
import ServerTop from "../components/serverTop.vue";
import ServerFoot from "../components/serverFoot.vue";
import Title from "../components/trademarkRegistration/title.vue";

export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
    Title,
    ServerTop,
    ServerFoot,
  },
  data() {
    return {
      serverData: [
        {
          title: "线上咨询",
          content: "在线咨询客服，对接专业商标顾问电话联系您，充分沟通分析商标驳回原因，评估商标复审成功率。",
        },
        {
          title: "准备复审材料",
          content: "费用支付后，商标顾问会根据商标复审要求，协助准备商标复审资料打印盖章/签字，并核对资料是否合规。",
        },
        {
          title: "律师撰写复审材料",
          content: "多年商标服务经验的律师亲自撰写商标复审材料，成分论述事实，尽最大程度去争取商标复审成功。",
        },
        {
          title: "资料提交商标局",
          content: "资料核对全部准备齐全后，会及时将商标驳回复审材料提交到国家商标局进行复审。",
        },
        {
          title: "复审结果通知",
          content: "国家商标局约在6-10个月后会下发商标复审通知，复审成功的商标进入初审通过公告程序。",
        },
        {
          title: "复审完成",
          content: "",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.one {
  background-image: url("../imger/Insurance/one.png");
  background-size: 100% 100%;
}
.tow {
  background-image: url("../imger/Insurance/2.png");
  background-size: 100% 100%;
}
.three {
  background-image: url("../imger/Insurance/3.png");
  background-size: 100% 100%;
}
.for {
  background-image: url("../imger/Insurance/4.png");
  background-size: 100% 100%;
}
.index {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .build {
    width: 100%;
    height: 28.75rem /* 460px -> 28.75rem */;
    background-image: url("../imger/reject/banner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .importance {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .header {
      width: 75rem /* 1200px -> 75rem */;
      height: 3.125rem /* 50px -> 3.125rem */;
      padding: 0.625rem /* 10px -> 0.625rem */;
      box-sizing: border-box;
      font-size: 1.25rem /* 20px -> 1.25rem */;
      color: #333333;
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      .lan {
        width: 0.3125rem /* 5px -> 0.3125rem */;
        height: 1.6875rem /* 27px -> 1.6875rem */;
        margin-right: 0.875rem /* 14px -> 0.875rem */;
        background-color: #4d89f1;
      }
    }
    .title {
      width: 100%;
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3.125rem /* 50px -> 3.125rem */;
      .titletubiaoleft {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-right: 1.25rem /* 20px -> 1.25rem */;
      }
      .titletubiaoright {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-left: 1.25rem /* 20px -> 1.25rem */;
      }
    }
    .word {
      margin-top: 3.125rem /* 50px -> 3.125rem */;
      width: 75rem /* 1200px -> 75rem */;
      font-size: 1.25rem /* 20px -> 1.25rem */;
      color: #333;
      line-height: 2.6875rem /* 43px -> 2.6875rem */;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-indent: 2.5rem /* 40px -> 2.5rem */;
    }
    .bigbox {
      width: 74.8125rem /* 1197px -> 74.8125rem */;
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
        font-size: 1.5rem /* 24px -> 1.5rem */;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3.125rem /* 50px -> 3.125rem */;
        .titletubiaoleft {
          width: 1.625rem /* 26px -> 1.625rem */;
          height: 100%;
          margin-right: 1.25rem /* 20px -> 1.25rem */;
        }
        .titletubiaoright {
          width: 1.625rem /* 26px -> 1.625rem */;
          height: 100%;
          margin-left: 1.25rem /* 20px -> 1.25rem */;
        }
      }
      .bigboxBox {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 3.125rem /* 50px -> 3.125rem */;
        .tiao{
          width: 36.25rem /* 580px -> 36.25rem */;
          height: 12.4375rem /* 199px -> 12.4375rem */;
          background-image: url('../imger/reject/tiao.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          .tiaoWord01{
            font-size: 1.375rem /* 22px -> 1.375rem */;
            color: #333;
            position: relative;
            left: 4.1875rem /* 67px -> 4.1875rem */;
            margin-top: 1.25rem /* 20px -> 1.25rem */;
          }
          .tiaoWord02{
            width: 31.5rem /* 504px -> 31.5rem */;
            font-size: 1.125rem /* 18px -> 1.125rem */;
            color: #666;
            position: relative;
            left: 4.1875rem /* 67px -> 4.1875rem */;
            margin-top: 1.5rem /* 24px -> 1.5rem */;
          }
        }
      }
      .advantage {
        width: 100%;
        height: 8.375rem /* 134px -> 8.375rem */;
        display: flex;
        justify-content: space-around;
        margin-top: 3.125rem /* 50px -> 3.125rem */;
        margin-bottom: 3.125rem /* 50px -> 3.125rem */;
        .advantageBox {
          width: 17.5625rem /* 281px -> 17.5625rem */;
          height: 100%;
          .advantageBoxword {
            position: relative;
            left: 6.875rem /* 110px -> 6.875rem */;
            width: 10rem /* 160px -> 10rem */;
            height: 100%;
            display: flex;
            flex-direction: column;
            .advantageBoxTitle {
              font-size: 1.25rem /* 20px -> 1.25rem */;
              color: #333;
              margin-top: 1.875rem /* 30px -> 1.875rem */;
            }
            .advantageBoxWera {
              font-size: 1rem /* 16px -> 1rem */;
              color: #666;
              margin-top: 0.625rem /* 10px -> 0.625rem */;
            }
          }
        }
      }
    }
  }
  .process {
    width: 75rem /* 1200px -> 75rem */;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    hr {
  width: 90%;
  height: 0.0625rem /* 1px -> 0.0625rem */;
  border: none;
  border-top: 0.0625rem /* 1px -> 0.0625rem */ dashed #4D89F1;
  margin-top: 1.9375rem /* 31px -> 1.9375rem */;
}
.AR01 {
  width: 100%;
  margin-top: 2.6875rem /* 43px -> 2.6875rem */;
  border: 0.0625rem /* 1px -> 0.0625rem */ solid #eaeaea;
  padding-bottom: 2.75rem /* 44px -> 2.75rem */;
  margin-bottom: 2.75rem /* 44px -> 2.75rem */;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    height: 3.125rem /* 50px -> 3.125rem */;
    padding: 0.625rem /* 10px -> 0.625rem */;
    box-sizing: border-box;
    font-size: 1.25rem /* 20px -> 1.25rem */;
    color: #333333;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    .lan {
      width: 0.3125rem /* 5px -> 0.3125rem */;
      height: 1.6875rem /* 27px -> 1.6875rem */;
      margin-right: 0.875rem /* 14px -> 0.875rem */;
      background-color: #4d89f1;
    }
  }
  .materials {
    width: 100%;
    display: flex;
    margin-top: 2.5rem /* 40px -> 2.5rem */;
    .materialsBox {
      width: 50%;
      .title {
        font-size: 1.25rem /* 20px -> 1.25rem */;
        color: #555555;
        margin-left: 2.0625rem /* 33px -> 2.0625rem */;
      }
      .field {
        display: flex;
        font-size: 1.125rem /* 18px -> 1.125rem */;
        color: #666666;
        position: relative;
        line-height: 2.5rem /* 40px -> 2.5rem */;
        margin-left: 3.5rem /* 56px -> 3.5rem */;
        margin-top: 1.25rem /* 20px -> 1.25rem */;
        .beizhu{
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #FF931F;
        }
        .num {
          line-height: 1.875rem /* 30px -> 1.875rem */;
          font-size: 2.25rem /* 36px -> 2.25rem */;
          color: #4d89f1;
        }
        .bei {
          position: absolute;
          bottom: 0.625rem /* 10px -> 0.625rem */;
          left: 0.5rem /* 8px -> 0.5rem */;
          width: 14.1875rem /* 227px -> 14.1875rem */;
          height: 0.5rem /* 8px -> 0.5rem */;
        }
      }
    }
  }
}
  }
}
</style>